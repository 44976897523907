import React from 'react'
import { Heading } from 'theme-ui'
import Divider from '@components/Divider'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    background: t => `
      linear-gradient(
        45deg,
        ${t.colors.alpha} 32.5%,
        ${t.colors.betaDark} 50.5%)
    `,
    color: `beta`,
    WebkitBackgroundClip: `text`,
    WebkitTextFillColor: `transparent`,
    fontWeight: `bold`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `normal`,
    maxWidth: [`full`, null, `2/3`]
  }
}

export default () => (
  <>
    <Divider space={3} />
    <Heading variant='h1' sx={styles.heading}>
      底辺低学歴からでも
      <br />
      人生一発逆転できます！！
    </Heading>
    <Divider space={3} />
    <Heading variant='h3' sx={styles.running}>
      世の中お金が全てじゃないですが、お金がないと生きていけません。
      パンデミック以前から、世界的に景気が悪くなり、ご存じのとおり日本も例外ではありません。<br></br>
      実際色々なモノの値段が上がる一方、賃金は上がるどころか税金の支払いだけが増えました。<br></br>
      このまま日本でジリ貧になるか、一発逆転狙うか、それは全てアナタ次第。
    </Heading>
  </>
)
